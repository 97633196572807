import React, {Component} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {pick} from 'lodash';

import MarketLayout from 'web/components/market_layout';

import {actions, reducer} from './duck';
import PasswordConnectedForm from '../components/password_form';

class SetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderPaswordForm() {
    let title, placeholder, hint;
    if (this.props.user.hasPassword) {
      title = 'Create password';
      placeholder = 'New password';
    } else {
      title = 'One quick thing';
      placeholder = 'Create a password (must be 8 characters or more)';
      hint = 'Why? A password lets you access your order history and delivery preferences';
    }
    return (
      <div className="set-password-form">
        <h1 className="checkout__title">{title}</h1>
        <hr />
        <PasswordConnectedForm
          onSubmit={this.props.handleSubmit}
          placeholder={placeholder}
          hint={hint}
        />
      </div>
    );
  }

  renderConfirmation() {
    return (
      <div>
        <h1 className="checkout__title">Great!</h1>
        <hr />
        <div className="account-page__greeting">
          Your password has been saved and you are now signed in.
        </div>
        <a className="button set-password-page__confirm" href={this.props.returnTo}>
          Continue
        </a>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout>
        <Helmet>
          <title>Choose a new password | Good Eggs</title>
        </Helmet>

        <div className="set-password-page narrow-page gutter">
          {this.props.setPasswordPage.passwordSaved
            ? this.renderConfirmation()
            : this.renderPaswordForm()}
        </div>
      </MarketLayout>
    );
  }
}

SetPasswordPage.propTypes = {
  setPasswordPage: PropTypes.object,
  globalError: PropTypes.object,
  handleSubmit: PropTypes.func,
  returnTo: PropTypes.string.isRequired,
  user: PropTypes.object,
};

SetPasswordPage.reducer = function (state, action) {
  const newState = MarketLayout.reducer(state, action);
  return reducer(newState, action);
};

SetPasswordPage.pageName = 'Choose New Password';

function mapStateToProps(state) {
  return pick(state, ['user', 'returnTo', 'form', 'setPasswordPage']);
}

function mapDispatchToProps(dispatch) {
  return {
    handleSubmit: () => dispatch(actions.save()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage);
