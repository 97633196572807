import {
  FINISHED_SAVING,
  actions as passwordFormActions,
  reducer as passwordFormReducer,
} from '../components/password_form/duck';

export const actions = {
  save() {
    return function (dispatch) {
      dispatch(passwordFormActions.save());
    };
  },
};

export function reducer(state = {}, action = {}) {
  const newState = passwordFormReducer(state, action);
  if (!newState.setPasswordPage) {
    newState.setPasswordPage = {};
  }
  switch (action.type) {
    case FINISHED_SAVING:
      return {
        ...newState,
        setPasswordPage: {
          ...newState.setPasswordPage,
          passwordSaved: true,
        },
      };
    default:
      return newState;
  }
}
